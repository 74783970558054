<template>
  <div v-if="isLoaded" class="opp-container">
    <div class="opp-top">
      <div class="search-bar opp-search-bar">
        <div>
          <ion-icon name="search-outline" class="small-icon"></ion-icon>
        </div>
        <input
            type="text"
            name="search"
            placeholder="Search job title, companies, etc."
            v-model="search"
        />
      </div>
    </div>

    <div class="navigate-to-wishlist">
      <p>Didn't see what you are looking for? Tell alumni what you need in <u @click="navigateToWishlist">Your Wishes</u>.</p>
    </div>

    <div class="referral-box">
      <ul v-for="opps in modifiedOppData" :key="opps.id" class="referral-row">
        <OppCard
            v-for="opp in opps.data"
            :key="opp.id"
            :data="opp"
            @click="handleClick(opp.id)"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import { useRouter } from "vue-router";
import { ref, watchEffect, onMounted } from "vue";
import getFilteredOpps from "@/composables/Opp/getFilteredOpps";
import OppCard from "@/components/Opportunities/oppCard.vue";

export default {
  components: {
    OppCard,
  },
  async setup() {
    const { user } = getUser();
    const router = useRouter();
    const oppData = ref(null);
    const searchOppData = ref(null);
    const oppDataLength = ref(null);
    const modifiedOppData = ref(null);
    const search = ref(null);
    const noSpecial = ref(null);
    const isLoaded = ref(false);
    noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;

    onMounted(async () => {
      await getFilteredOpps(oppData);

      watchEffect(() => {
        searchOppData.value = oppData.value;

        searchOppData.value = searchOppData.value.filter((opp) => {
          if (search.value == null) {
            return true;
          } else if (noSpecial.value.test(search.value)) {
            // console.log("no specials");
            return true;
          }
          return opp.title.toLowerCase().match(search.value.toLowerCase());
        });

        const countOpps = (searchOppData) => {
          let oppCounter = 0;
          searchOppData.value.forEach((opp) => {
            oppCounter++;
          });
          // console.log(oppCounter);
          return oppCounter;
        };

        oppDataLength.value = countOpps(searchOppData);

        const modifyOppData = (searchOppData) => {
          let result = [];
          for (let i = 0; i < Math.ceil(oppDataLength.value / 3); i++) {
            result.push({ data: [], id: i });
          }
          let counter = 0;
          searchOppData.value.forEach((opp) => {
            result[Math.floor(counter / 3)].data.push(opp);
            counter++;
          });
          return result;
        };

        modifiedOppData.value = modifyOppData(searchOppData);
      });

      isLoaded.value = true;
    });

    const handleClick = (oppId) => {
      router.push({ name: "Junior-OppDetail", params: { id: oppId } });
    };

    const navigateToWishlist = () => {
      router.push({ name: "Junior-WishList"});
    }

    return { isLoaded, search, modifiedOppData, handleClick, navigateToWishlist };
  },
};
</script>

<style scoped>
.opp-container {
  padding: 3vh;
  flex: 1 0 auto;
  animation: fadein 1s;
}

.opp-top {
  display: flex;
  margin: 2vh 0 3vh 0;
}

.opp-search-bar {
  margin-left: 2vw;
  padding: 0 1vh;
  width: 10vw;
  flex: 0 1 100%;
  max-width: 20rem;
}

.navigate-to-wishlist {
  position: relative;
  background-color: white;
  width: 93%;
  left: 2.5%;
  margin-bottom: 3vh;
  border: 2px dashed #cccccc;
  border-radius: 20px;
  word-wrap: break-word;
}

.navigate-to-wishlist p {
  display: inline-block;
  font-size: 20px;
  padding: 10px;
  line-height: 40px;
}

.navigate-to-wishlist p u:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navigate-to-wishlist p {
    width: 100%;
    line-height: 30px
  }

}

.referral-row {
  list-style: none;
  display: flex;
}

@media screen and (max-width: 850px) {
  .opp-container {
    position: relative;
    left: -10px;
  }

  .opp-container {
    padding: 0;
  }
}

@media screen and (max-width: 630px) {
  .opp-container {
    position: static;
  }
  .referral-row {
    flex-direction: column;
  }
}
.home-opp{
  animation: fadein 1s;
}

.opp-title {
  padding: 3vh;
}

.opp-bar {
  display: flex;
  flex-wrap: wrap !important;
  padding: 0 3vh;
}

.opp-bar-list {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 60%;
}

.opp-bar-button {
  height: 100%;
  display: inline-block;
  font-size: 110%;
  margin-right: 3vh;
  text-align: center;
}

.opp-bar-button h4 {
  margin-top: 1vh;
  font-weight: 400;
}

.default-opp-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-opp-column h3 {
  text-align: center;
  vertical-align: middle;
  margin: 8vh 0 4vh 0;
}

.default-opp-column button {
  text-align: center;
  vertical-align: middle;
  padding: 2vh 4vh;
  color: white;
  font-size: 120%;
  font-weight: 500;
  background-color: var(--purple-l);
  border-radius: 4vh;
  border: none;
}

.default-opp-column button:hover {
  background-color: var(--purple);
  cursor: pointer;
}

.opp-column {
  background-color: #f8f8f8;
  border-radius: 3vh;
  padding: 1vh 3vh;
  margin-top: 3vh;
  align-items: center;
  list-style: none;
}

.opp-column-item {
  display: flex;
  border-bottom: 0.5px rgba(0, 0, 0, 0.5) solid;
  cursor: pointer;
}

.last-item {
  border-bottom: none;
}

.opp-item-left {
  flex: 0 1 50%;
  padding: 2vh 0;
}

.opp-item-left h2 {
  line-height: 3vh;
}

.opp-item-left h4 {
  padding-top: 0.5vh;
  font-size: 120%;
  font-weight: 200;
  line-height: 3vh;
}

.opp-item-right {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
}

.opp-item-icon {
  flex: 0 1 9%;
  align-items: center;
  padding-right: 2vh;
}

.opp-item-status {
  flex: 1 0 91%;
  font-size: 125%;
}

.opp-item-status h4 {
  width: 100%;
  font-size: 80%;
  font-weight: 400;
}

@media screen and (max-width: 600px){
  .opp-item-right {
    flex-wrap: wrap;
  }

  .opp-item-status h4 {
    font-size: 10px;
  }
}

.search-bar {
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.search-bar div {
  flex: 0 1 10%;
  color: #808080;
  font-size: 80%;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.search-bar input,
.search-bar input:focus {
  display: block;
  flex: 0 0 80%;
  color: #808080;
  height: 4vh;
  background-color: white;
  border: none;
  outline: none;
}

.opp-search-bar {
  min-width: 12rem;
}
</style>
